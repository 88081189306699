$invalid-bd-color:  red;
$label-color: gray;
$placeholder-color: #c0bdbd;
$theam-color: #0f4985;
$bdr-0: 0px;
$btn-primary:#3071a9;
$btn-success: #FF6600;
$btn-reset: #A9A9A9;
$btn-dander:#D00000;
$gray:gray;
$bg-light-transparent: rgba(255,255,255,0.5);
$bg-primary:#3071a9;
$bg-body: #fbfdffba;
$bg-footer: #f0f0f0;
$title-bg:#cddbe3;

$color1:#7fa211;
$color2:#f03d07;
$color3:#7ea10f;
$color4:#fb7907;
$color5:#fb7806;
$color6:#31acaa;
$color7:#019596;
$color8:#faad13;
$color9:#009fe9;
$color10:#1aaf46;
$color333:#333333;
$color-primary:#3071a9;

$lgradiant1:linear-gradient(0deg, #028afb, #02dcf6);
$lgradiant2:linear-gradient(0deg, #7f24da, #b47ceb);
$lgradiant3:linear-gradient(0deg, #f63d40, #fc79a9);
$lgradiant4:linear-gradient(0deg, #f77621, #f5b667);

$bg-color1:#5f5379;
$bg-color2:#1aa19a;
$bg-color3: #3384b4;