.inlinedate {
    .bs-datepicker-body {
        table.days {
            span {
                width: 33px;
                height: 33px;
                line-height: 33px;
            }
        }
    }
}
.inlinedate {
    .bs-datepicker-head {
        min-width: unset;
    }
    .bs-datepicker-body{
        min-width: unset;
    }
}