.correct-answer {
    .mat-radio-button.mat-radio-disabled {
        .mat-radio-label-content {
            color: #28a745;
            position: relative;
        }
        .mat-radio-outer-circle{
            border-color: #28a745;
        }
        .mat-radio-inner-circle {
            background-color: #28a745;
        }
    }
    .mat-icon {
        height: 20px;
        width: 20px;
        font-size: 14px;
        position: absolute;
        top: 4px;
        right: -30px;
    }
    
    
    .mat-checkbox-disabled.mat-checkbox-checked {
        .mat-checkbox-background {
            background-color: #28a745;
        }
        
        .mat-checkbox-label {
            color: #28a745;
            position: relative;
        }
    }
    .mat-checkbox-disabled:not(.mat-checkbox-checked) {
        .mat-checkbox-frame {
            border-color: #28a745;
        }
        .mat-checkbox-label {
            color: #28a745;
            position: relative;
        }
    }
}
.wrong-answer {
    .mat-radio-button.mat-radio-disabled {
        .mat-radio-label-content {
            color: #dc3545;
            position: relative;
        }
        .mat-radio-outer-circle{
            border-color: #dc3545;
        }
        .mat-radio-inner-circle {
            background-color: #dc3545;
        }
    }
    .mat-icon {
        height: 16px;
        width: 16px;
        font-size: 14px;
        position: absolute;
        top: 4px;
        right: -30px;
    }
    
    .mat-checkbox-disabled {
        .mat-checkbox-background {
            background-color: #dc3545;
        }
        .mat-checkbox-label {
            color: #dc3545;
            position: relative;
        }
    }
}