


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.



// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hw-primary: mat-palette($mat-indigo);
$hw-accent: mat-palette($mat-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hw-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hw-theme: mat-light-theme((
  color: (
    primary: $hw-primary,
    accent: $hw-accent,
    warn: $hw-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($hw-theme);

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

@import 'assets/hw-theam/hw-custom-style';


.modal-title{
    color:#ffffff;
}

.lang{
    width:25px;
    height: 25px;
    display: inline-block;
    &.en{
        background-image: url(../src/assets/images/icons/us1.png);
        background-size: 100%;
        background-repeat:  no-repeat;
    }
    &.da{
        background-image: url(../src/assets/images/icons/dk1.png) ;
        background-size: 100%;
        background-repeat:  no-repeat;
    }
}
.bg-light-transparent{
    background-color: $bg-light-transparent;
}
.btn{
    &.btn-primary{
        background-color: $btn-primary;
        border-color: $btn-primary;
    }
   
}
.navbar{
    box-shadow: 0px 5px 10px #d3d3d3 !important;
    .navbar-toggler{
        background-color: $color2;
    }
}

.nav-item{
    .navbar-brand{
        color: $color1 !important;
        &.active{
            position: relative;
            color: $color1 !important;
            &:before{
                content: "";
                position: absolute;
                width: 80%;
                height: 2px;
                background-color: $color2 !important;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                border: none;
            }
        }
        :focus{
            color: $color1 !important;
        }
        :hover{
            color: $color1 !important;
        }
        
    }
}
.form-group {
    label{
        color:$label-color;
    }
    input,textarea,select,label{
        font-weight: 500;
        &::placeholder{
            color: $placeholder-color;
            font-weight: 300;
        }
        &.invalid{border-color: $invalid-bd-color;}
        &.form-control{
            padding: 5px 5px; 
            min-height: 30px;
            border-radius: 0px;
            margin-bottom: 8px;
            &:focus{
                box-shadow: none;
            }
        }
        
    }
    button{
        &.form-control{
            min-height: 30px;
            height: 31px;
            line-height: 29px;
            padding: 0 .75rem;
            border-radius: 0px;
            color: #fff;
            min-width: 100px;
            &:focus{
                box-shadow: none;

            }
            &:active:focus{
                box-shadow: none !important;
            }
        }
    }
}

thead {
    th{
        font-weight: 400;
    }
}
table {
    tbody {
        tr{
            &:hover{
                background-color: #fffbf2;
                cursor: pointer;
            }
        }
    }
}

html, body { height: 100%; }
body { margin: 0;  }
.mat-body, .mat-body-1, .mat-typography ,.mat-card-content{
    font: 300 16px/20px Roboto, "Helvetica Neue", sans-serif !important;
}
.mat-typography {
    label:not(.form-control){
            font: 300 12px/20px Roboto, "Helvetica Neue", sans-serif !important;
        }
                
        
    
}
.box-shadow{
    box-shadow: 0px 2px 5px #d2d2d2;
}


// Material Angular Styles
.mat-menu-panel {
    border-radius: 0px !important;
    min-height: 25px !important;
    border: 1px solid #0f4985;
    box-shadow: none;
}
.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
// .mat-card-header-text {
//     margin: 0px !important;
// }

.text-left{
    .mat-figure{
        justify-content: left !important;
    }
}
.text-right{
    .mat-figure{
        justify-content: flex-end !important;
    }
}
.audio-poster-image{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px 15px;
}


// Calender
.bs-datepicker-container{
    padding: 0px;
}



/* custom header */

.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customSortUpLabel {
    float: left;
    margin: 0;
  }
  
  .customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
  }
  
  .active {
    color: cornflowerblue;
  }





.tab-content{
    padding: 0px 1rem 5px;
}