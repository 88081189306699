@import 'variable';

//   owl Carousel
.owl-theme .owl-nav {
    margin-top: 0px;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.owl-prev {
    left: 0px;
}

.owl-next {
    right: 0px;
}

.owl-theme .owl-nav [class*=owl-] {
    padding: 0px 7px 14px !important;
    background: #a7a7a7b5 !important;
    line-height: initial;
    box-shadow: 0px 10px 10px #ffffff9c, 0px -10px 10px #ffffff9c;
    font-size: 100px;
}

.owl-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}


.multy-item-carousel .owl-theme .owl-nav [class*=owl-] {
    padding: 0px 4px 5px 4px !important;
    background: #acacac00 !important;
    line-height: initial;
    box-shadow: 0px 1px 1px #ffffff00, 0px -1px 1px #4d4d4d00;
    font-size: 50px;
    line-height: 50px;
    color: #000;

}

.multy-item-carousel .owl-prev {
    left: -25px;
}

.multy-item-carousel .owl-next {
    right: -25px;
}

.multy-item-carousel .owl-item {
    border: 1px solid #d3d3d3;
}


.multy-item-carousel.mobileAppOptions {
    .owl-theme {
        .owl-prev {
            height: 100%;
            margin: 0px !important;
            width: 10.5% !important;
            left: -11.5%;
            display: flex;
            align-items: center;
            background-color: #f2f6f8 !important;
            border-radius: 0px 30% 30% 0px !important;
        }

        .owl-next {
            height: 100%;
            margin: 0px;
            width: 10.5% !important;
            right: -11.5%;
            display: flex !important;
            align-items: center;
            background-color: #f2f6f8 !important;
            border-radius: 30% 0% 0% 30% !important;
        }
    }

    .owl-item {
        border: none !important;
    }

    p {
        color: #333333;
    }

    .divider {
        width: 2px;
        height: 100%;
        background-color: $bg-primary;
        margin: auto;
    }
}

.multy-item-carousel.feedbackOptions {
    .owl-theme {
        .owl-prev {
            height: 100%;
            margin: 0px !important;
            width: 10.5% !important;
            left: -11.5%;
            display: flex;
            align-items: center;
            background-color: #f2f6f8 !important;
            border-radius: 0px 30% 30% 0px !important;
        }

        .owl-next {
            height: 100%;
            margin: 0px;
            width: 10.5% !important;
            right: -11.5%;
            display: flex !important;
            align-items: center;
            background-color: #f2f6f8 !important;
            border-radius: 30% 0% 0% 30% !important;
        }
    }

    .owl-item {
        border: none !important;

        img {
            width: 100% !important;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
    }

    p {
        color: #333333;

        mat-icon {
            height: 12px !important;
            font-size: 12px;
            width: 12px !important;
        }
    }

    .divider {
        width: 2px;
        height: 100%;
        background-color: $bg-primary;
        margin: auto;
    }

    .active {
        .row.justify-content-center.pb-3.ng-star-inserted {
            border-right: 2px solid #3071a9 !important;
            justify-content: space-around;
            align-items: center;
            flex-wrap: nowrap;
        }
    }
}

.HLCPOptions {
    .owl-theme {
        .owl-prev {
            height: 100%;
            margin: 0px !important;
            width: 10.5% !important;
            left: -11.5%;
            display: flex;
            align-items: center;
            background-color: #f2f6f8 !important;
            border-radius: 0px 30% 30% 0px !important;
        }

        .owl-next {
            height: 100%;
            margin: 0px;
            width: 10.5% !important;
            right: -11.5%;
            display: flex !important;
            align-items: center;
        }
    }

    .owl-item {
        border: none !important;
    }
}

.image2 {
    position: relative;

    ng-lottie {
        position: absolute;
        width: 100%;
        height: 100%;

        div {
            max-width: 60%;
            height: auto !important;

        }
    }
}

.slider {
    h2 {
        &.title {
            font-size: 40px !important;
            line-height: 45px !important;
        }
    }
}

.social-impact-youtube{
    .multy-item-carousel {
        .owl-theme {
            .owl-nav {
                .owl-next{
                    font-size: 100px;
                font-weight: bolder;
                background-color: #bbcef9 !important;
                padding-bottom: 15px !important;
                }
                .owl-prev{
                    font-size: 100px;
                font-weight: bolder;
                background-color: #bbcef9 !important;
                padding-bottom: 15px !important;
                }
            }
        }
        .owl-item{
            border: none;
        }
    }
    
}