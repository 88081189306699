@import 'variable';

@media (max-width: 576px){
    .owl-theme .owl-nav [class*=owl-] {
        font-size: 30px;
        margin: 0px;
    }
    .owl-dots{
        bottom: -5px;
    }
    #navbarNav.navbar-collapse.collapse{
        position: absolute;
        top: 90px;
        z-index: 11;
        background: #fff;
        width: 100%;
        left: 0;
        padding: 15px;
        box-shadow: 0px 5px 10px #d2d2d2, 0px -2px 6px #d2d2d2;
    }
    .event-group{
        width: 100%;
    }
    .inline-calander-group {
        width: 100%;
    }
    .xs-flex-wrap{
        flex-wrap: wrap;
    }
    .xs-justify-content-center{
        justify-content: center !important;
    } 
    .xs-w-100{
        width: 100% !important;
    }
    .banner{
        // padding-top: 85px;
        background-color: #edeef0;
        // .banner-image{
        //     top: 85px;
        // }
        .banner-content-left{
            // top: 85px;
            // transform: translateY(0px);
            h1{
                font-size: 20px;
                width: 80%
               
            }
        }
    }
    .topnav{ 
        &.bg-transparet{
            position: relative;
                transform: translate(0%, 0px);
                left: 0;
            nav{
                &.navbar{
                    background-color: #ececef !important;
                }
            }
        }

    }
    .text-content {
        z-index: 1;
        h1{
            font-size: 20px !important;
            line-height: 25px !important;
        }
    }
    .bg-half-color{
        &:after {
            height: 100%;
        }
    }
    .investing-yourself{
        .cart-investing{
            margin-bottom: 40px !important;
        }
    }
    .award-group {
        margin: 0px 0px 30px 20% !important;
    }
    .award-icon-group {
        bottom: -60px !important;
        left: 0px;
        top: unset;
    }
    .images-style1{
        .image3 {
            top: 50% !important;
            right: 0% !important;
            transform: translate(0px, -50%) !important;
            justify-content: center !important;
            justify-items: center !important;
            div{
                align-self: center;
            }
        }
    }
    
    .page-faqs {
        .calendarManagement {
            .card-header{
                &.collapsed {
                    left: -30px;
                    .title {
                        width: calc(100% - 30px);
                        margin-left: 5px;
                    }
                } 
                &:not(.collapsed) .icon-arrow {
                    position: absolute;
                    left: -20px;
                }
            }
        }
    }
    .d-xs-none{
        display: none;
    }
    
    
}
@media (max-width: 768px) {
    
}
@media (min-width: 768px){
    .modal-dialog {
        max-width: 720px;
    }
    .modal-dialog.modal-md{
        max-width: 678px;
    }
    .model-dialog.model-sm{
        max-width: 500px;
    }
}

@media (min-width: 992px){
    .modal-dialog {
        max-width: 960px;
    }
    .modal-dialog.modal-md{
        max-width: 678px;
    }
    .model-dialog.model-sm{
        max-width: 500px;
    }
}
@media (min-width: 1200px){
    .modal-dialog {
        max-width: 1140px;
    }
    .modal-dialog.modal-md{
        max-width: 678px;
    }
    .modal-dialog.model-sm{
        max-width: 500px;
    }
}
