.p-5px{
    padding: 5px !important;
}
.pb-2px{
    padding-bottom: 2px !important;
}
.p-10px{
    padding: 10px !important;
}
.pt-10px{
    padding-top: 10px !important;
}
.pl-5px{
    padding-left: 5px !important;
}
.pr-5px{
    padding-right: 5px !important;
}
.pt-5px{
    padding-top: 5px !important;
}
.pb-5px{
    padding-bottom: 5px !important;
}
.pl-10px{
    padding-left: 10px !important;
}
.pr-10px{
    padding-right: 10px !important;
}
.pb-10px{
    padding-bottom: 10px !important;
}
.pt-15px{
    padding-top: 15px !important;
}
.pl-15px{
    padding-left: 15px !important;
}
.pr-15px{
    padding-right: 15px !important;
}
.pb-15px{
    padding-bottom: 15px !important;
}
.pt-12px{
    padding-top: 12px !important;
}
.pl-12px{
    padding-left: 12px !important;
}
.pr-12px{
    padding-right: 12px !important;
}
.pb-12px{
    padding-bottom: 12px !important;
}
.pt-100px{
    padding-top: 100px !important;
}
.pb-100px{
    padding-bottom: 100px !important;
}
.px-80px{
    padding-right: 80px !important;
    padding-left: 80px !important;
}
.py-80px{
    padding-top: 80px !important;
    padding-bottom: 80px !important;
}
// margin
.m-5px{
    margin: 5px !important;
}
.mb-2px{
    margin-bottom: 2px !important;
}
.mlr-15px-neg{
    margin-left: -15px !important;
    margin-right: -15px !important;
}
.ml-5px{
    margin-left: 5px !important;
}
.mr-5px{
    margin-right: 5px !important;
}
.mt-5px{
    margin-top: 5px !important;
}
.mb-5px{
    margin-bottom: 5px !important;
}
.m-10px{
    margin: 10px !important;
}
.mt-10px{
    margin-top: 10px !important;
}
.ml-10px{
    margin-left: 10px !important;
}
.mr-10px{
    margin-right: 10px !important;
}
.mb-10px{
    margin-bottom: 10px !important;
}
.mt-15px{
    margin-top: 15px !important;
}
.ml-15px{
    margin-left: 15px !important;
}
.mr-15px{
    margin-right: 15px !important;
}
.mb-15px{
    margin-bottom: 15px !important;
}
.mt-12px{
    margin-top: 12px !important;
}
.ml-12px{
    margin-left: 12px !important;
}
.mr-12px{
    margin-right: 12px !important;
}
.mb-12px{
    margin-bottom: 12px !important;
}
.mt-100px{
    margin-top: 100px !important;
}
.mb-100px{
    margin-bottom: 100px !important;
}
.min-h-60px{
    min-height: 60px !important;
}
.max-w-500px {
    max-width: 500px !important;
}
@media (max-width: 576px) {
    .xs-ml-0px{
        margin-left: 0px !important;
    }
    .xs-mt-3{
        margin-top: 1rem !important;
    }
    .xs-pb-20px{
        padding-bottom: 20px !important;
    }
    xs-pb-20px{
        padding-bottom: 60px !important;
    }
    .sx-flex-wrap{
        flex-wrap: wrap;
    }
    .xs-mt-20px{
        margin-top: 20px !important;
    }
    .px-xs-0px{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .px-xs-30px{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .calendarManagement {
        .card-header.collapsed {
           
            left: 0px !important;
        }
    }
}
@media (max-width: 992px){
    .sm-m-auto{
        margin: auto;
    }
    .sm-flex-wrap{
        flex-wrap: wrap;
    }
    .sm-w-100{
        width: 100%;
    }
}


.top-30px{
    top:30px !important;
}
.z-index-10{
    z-index: 10 ;
}
.max-width-100px{
    max-width: 100px;
}
.max-width-50px{
    max-width: 50px;
}
.mx-w-300px{
    max-width: 300px !important;
}
.mx-w-130px{
    max-width: 130px !important;
}
.position-relative {
    position: relative !important;
}
.z-inxex-1{
    z-index: 1 !important;
}
.w-100px{
    width: 100px !important;
}
.w-90{
    width: 90% !important;
}
.w-80{
    width: 80% !important;
}
.w-70{
    width: 70% !important;
}

.w-60{
    width: 60% !important;
}
.w-55{
    width: 55% !important;
}

.w-45{
    width: 45% !important;
}
.w-40{
    width: 40% !important;
}
.w-35{
    width: 35% !important;
}
.w-30{
    width: 30% !important;
}
.w-55px{
    width: 55px !important;
}
.w-75px{
    width: 75px !important;
}
.w-60px{
    width: 60px !important;
}

.min-h-300px{
    min-height: 300px !important;
}
.h-25px{
    height: 25px;
}
.h-30px{
    height: 30px !important;
}
.mx-h-100vh{
    max-height: 100vh !important;
}
.lh-30px{
    line-height: 30px !important;
}
.shadow-08 {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.8)!important;
}
.bg-s-cover{
    background-size: cover !important;
    background-position: center;
}
.fix-bottom{
    bottom: 0px;
    position: absolute;
    width: 100%;
}
.text-transform-default{
    text-transform: inherit !important;
}
.mt-80px{
    margin-top: 80px !important;
}
.mb-80px{
    margin-bottom: 80px !important;
}
.mx-80px{
    margin-right: 80px !important;
    margin-left: 80px !important;
}
.my-80px{
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}
@media (min-width: 576px){
    .w-sm-90{
        width: 90% !important;
    }
    .w-sm-80{
        width: 80% !important;
    }
    .w-sm-70{
        width: 70% !important;
    }
    .w-sm-60{
        width: 60% !important;
    }
    .w-sm-50{
        width: 50% !important;
    }
    .w-sm-40{
        width: 40% !important;
    }
}