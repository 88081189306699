
.bg-theam{
    background-color: $theam-color;
}
.bg-gray{
    background-color: $gray;
}
.full-group{
    width: 100%;
}
.form-group label{
   margin-bottom: 0px  ;
}
.bd-dadios-0{
    border-radius: $bdr-0;
}
.align-items-center{
    align-items: center;
}
.justify-content-c{
    justify-content: center;
}
.font-weight-500{
    font-weight: 500;
}
.appearance-auto{
    appearance:auto;
    -webkit-appearance:auto;
}
.img-circle{
    border-radius: 100% !important;
}
.tex-decoration-underline{
    text-decoration: underline;
}

.card-devider{
    width: calc(100% + 32px);
    margin: 16px -16px;
    border-top: 1px solid #d3d3d3;
}
.example-card{
    padding-bottom: 68px !important;
}
.mat-card{
    height: 100%;
    
    
    .mat-card-content{
        h1{
            text-align: center;
        }
        .card-devider{
            position: inherit;
            width: calc(100% + 32px);
            margin: 16px -16px;
            border-top: 1px solid #d3d3d3;
        }
    }
    .mat-card-actions{
        bottom: 8px;
        position: absolute;
        margin: 0 !important;
        width: calc(100% - 32px);
        a{
            display: block;
            text-align: center;
            button{
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }
    }
    .card-devider{
        bottom: 44px;
        position: absolute;
        margin: 0;
        width: calc(100% - 0px);
        left: 0px;
        border-top: 1px solid #d3d3d3;
    }

}



.bg-lgradiant1{
    background-image: $lgradiant1;
}
.bg-lgradiant2{
    background-image: $lgradiant2;
}
.bg-lgradiant3{
    background-image: $lgradiant3;
}
.bg-lgradiant4{
    background-image: $lgradiant4;
}
.overflow-visivle{
    overflow: visible !important;
}
.mat-button {
    .mat-button-focus-overlay{
        opacity: 0.04 !important;
    }
    
}
.theam-color-1{
    color:$color1 !important;
}
.theam-color-2{
    color:$color2 !important;
}
.theam-color-3{
    color:$color3 !important;
}
.theam-color-4{
    color:$color4 !important;
}
.theam-color-5{
    color:$color5 !important;
}
.theam-color-6{
    color:$color6 !important;
}
.theam-color-7{
    color:$color7 !important;
}
.theam-color-8{
    color:$color8 !important;
}
.theam-color-9{
    color:$color9 !important;
}
.theam-color-10{
    color:$color10 !important;
}
.theam-color-333{
    color: $color333 !important;
}
.text-black{
    color: #333333 !important;
}
.bg-theam-color-1{
    background-color:$bg-color1 !important;
}
.bg-theam-color-2{
    background-color:$bg-color2 !important;
}
.bg-theam-color-3{
    background-color: $bg-color3 !important;
}
.w-90{
    width: 90%;
}
.w-80{
    width: 80%;
}
.cursor-pointer{
    cursor: pointer;
}
.section-title{
    padding: 30px 0px;
}
.min-height-200{
    min-height: 200px;
}
.mat-tab-group{
    border: 1px solid #d8d8d8;
}
.mat-tab-list{
    background-color: #ffb100;
}
.mat-tab-labels .mat-tab-label-active{
    background: #fff;
    opacity: 1;
}
.mat-tab-body-content{
 min-height: 100px;
}

.section-pt-30{
    padding-top: 30px;
}

.min-h-fitscreen{
    min-height: calc(100vh - 160px);
}
.min-h-fitscreen1{
    min-height: calc(100vh - 240px);
}
// .mat-content-right{
//     margin-left: 260px;
// }
.mat-content-left{
    min-width: 250px;
}
.topnav.bg-transparet{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 9;
    background-color: transparent !important;
    .navbar{
        box-shadow: none !important;
    }
}
// #navbarNav.navbar-collapse.collapse.show{
//     position: absolute;
//     top: 90px;
//     z-index: 11;
//     background: #fff;
//     width: 100%;
//     left: 0;
//     padding: 15px;
//     box-shadow: 0px 5px 10px #d2d2d2, 0px -2px 6px #d2d2d2;
// }
.resizebele-false{
    resize: none;
}
.list-style-none{
    list-style: none;
}
.mat-drawer-side, .mat-card.mat-focus-indicator{
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    box-shadow: none;
}
.mat-radio-label-content-false .mat-radio-label-content{
    display: none;
}
.custom-p-1{
    padding:1px;
}
.custom-p-2{
    padding:2px;
}
.custom-p-3{
    padding:3px;
}
.custom-mt-1{
    margin-top:1px;
}
.r-0{
    right: 0;
}
.b-0{
  bottom: 0;  
}
.border-w-5{
    border-width: 5px !important;
}
.bg-light-transparent-05{
    background-color: rgba(248,249,250,0.5);
}
.bg-transparent .mat-tab-list{
    background-color: transparent;
}
.clear-both{
    clear: both;
}
.m-0 > .mat-card-header-text{
    margin: 0px;
}
.w-100 > .mat-card-header-text{
    width: 100%;
}
.w-27px{
    width: 27px !important;
}
.model-max-window-h{
    max-height: calc(100vh - 160px);
    overflow: auto;
    height: calc(100vh);
}
.model-min-window-h{
    min-height: calc(100vh - 160px);
   
}
.mlr-4px{
    margin-left: 4px;
    margin-right: 4px;
}
.min-height-150{
    min-height: 150px;
}
.max-height-150px{
    max-height: 150px;
}
// ol { 
//     counter-reset: item;
// }
// li { display: block; }
// li:before { 
//     content: counter(item) ""; 
//     counter-increment: item 
// }
.nav-item a,.nav-item a:hover{
    cursor: pointer;
}
.multiselect-dropdown > div:focus-visible{
    outline: none;
}
        
    

.multiselect-dropdown {
    div{
        outline: none !important;
        &:focus-visible{
            outline: none !important;
        }
        
    }
    .dropdown-btn {
        padding: 4px 5px !important;
        border-radius: 0px !important;
        margin-bottom: 8px !important;
        border-color: #ced4da !important;
        outline: none !important;
        .selected-item{
            border-width: 0px !important;
            white-space:nowrap;
        }
        .dropdown-multiselect__caret{
            line-height:12px !important;
            width:30px !important;
        }
    }
    .dropdown-list {
        margin-top: 0px !important;
    }
}
.addImageIcon{
    position: absolute;
    top: 0;
    right: 10px;
}
.edit-school-logo{
    height: 110px;
}
.event-group{
    width: calc(100% - 269px);
}
.inline-calander-group {
    width: 254px;
}
.progress-spinner-group{
    position: absolute !important;
    z-index: 9999;
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    // background-color: #000;
    // opacity: 0.6;
}
.progress-spinner-group>svg{
    position: absolute !important;
    top: calc(50% - 22.5px) !important;
    left: calc(50% - 22.5px) !important;
    transform: translate(-50%, -50%) !important;
}
.break-word{
    word-break: break-word;
}
img{
    max-width:100%;
    width:100%;
}
.assingment-item{
    position: relative;
    display: inline-block;
}
.assingment-item .stu-result{
    position: absolute;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: rgb(103 103 103 / 70%);
    color: #fff;
}
#accordion{ 
    .card-header{
        border-bottom: 0px !important;
    }
    .faq-btn-collapse{
        display: block;
        padding: 0.6rem 1rem ;
        border:0px;
        text-align: left;
        border-bottom: 1px solid rgba(0,0,0,.125);
        &.collapsed{
            border-bottom: 0px solid rgba(0,0,0,.125);
        }
        &:hover{
            text-decoration: none;
        }
    }
    .faq-content{
        padding: 0.6rem 1rem ;
    }
}
.profile{
    max-width: 130px !important;
}
.profile-footer{
    margin: 0px  0px 0px auto;
    width: fit-content;
    /* display: inline-block; */
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    /* float: right; */
    align-self: flex-end;
}
.list-inline-item:not(:last-child){
    margin-right: 0px;
}
iframe img{
    max-width: 100%;
    max-height: 100%;
}
.mat-radio-label-content{
    white-space: break-spaces;
}



/* Container shape style */
.shape-container-top {
    left: 0;
    line-height: 0;
    overflow: hidden;
    top: 0;
    transform: rotate(180deg);
    width: 100%;
}
.shape-container-top svg {
    display: block;
    height: 100px;
    position: relative;
    transform: rotateY(180deg);
    width: calc(100% + 1.3px);
}
img, svg {
    vertical-align: middle;
}
.shape-container-top .shape-fill {
    fill: #fff;
}
.shape-container-bottom {
    bottom: 0;
    left: 0;
    line-height: 0;
    overflow: hidden;
    transform: rotate(180deg);
    width: 100%;
}
.shape-container-bottom svg {
    display: block;
    height: 100px;
    position: relative;
    width: calc(100% + 1.3px);
}
.shape-container-bottom .shape-fill {
    fill: #fff;
}

.bg-footer{
    background-color: $bg-footer;
}
.bg-primary{
    background-color: $bg-primary !important;
}
.bg-theam-body {
    background-color: $bg-body !important;
}
.theam-title-bg{
    background-color: $title-bg !important;
}
.footer-social-icons{
    list-style: none;
    padding: 0px;
    margin: 0px;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
}
.footer-social-icons li{
    float: left;
    padding-left: 15px;
}
.footer-social-icons li a{
    color: #fff;
    cursor: pointer;
}
.bg-blue{background-color: #3384b4;}
.bg-half-color{
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 60%;
        background-color: #3384b4;
        top: 0px;
        z-index: 0;
    }
}
.section-header{
    .title{
        font-size: 25px;
        color: #3384b4;
        text-transform: uppercase;
    }
}
.investing-yourself{
    .header{
        .title{
            font-size: 35px;
            line-height: 40px;
        }
    }
}
.card-title-image{
    position: absolute;
    top: -30px;
    z-index: 1;
    left: 25px;
    img{
        width: 50px;
    }
}
.investing-yourself-card{
    .title{
        text-transform: uppercase;
        font-size: 16px;
        min-height: 80px;
        padding-top: 40px;
    }
}
.custom-list-style1{
    list-style: none;
    li{
        padding-left: 15px;
        position: relative;
        font-size: 16px;
        &:before{
            content: "";
            width: 7px;
            height: 16px;
            position: absolute;
            left: 0px;
            background-size: 7px 12px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .right-angle1{
        &:before{
            background-image: url(../images/2X/right-angle1.png);
        }
    }
    .right-angle2{
        &:before{
            background-image: url(../images/2X/right-angle2.png);
        }
    }
    .right-angle3{
        &:before{
            background-image: url(../images/2X/right-angle3.png);
        }
    }
    .right-angle4{
        &:before{
            background-image: url(../images/2X/right-angle4.png);
        }
    }
    .right-angle5{
        &:before{
            background-image: url(../images/2X/right-angle5.png);
        }
    }
}
.ourpartner-section{
    background-image: url(../images/2X/Group-601.png);
    padding: 50px 0px;
    background-size: cover;
}
.award-group{
    width: 60%;
    margin: 0px 0px 30px 40%;
}
.award-icon-group{
    position: absolute;
    top: 0;
    right: -50px;
    width: 60px;
}
.newest-education-section{
    .icon-group1{
        position: absolute;
        top: -20px;
        right: 0px;
        width: 75px;
    }
    .icon-group2{
        text-align: center;
        img{
            width: 60px;
        }
    }
    
}
.footer-menu{
    li{
        text-transform: uppercase;
        margin-bottom: 8px;
    }
}
.Social-media-group{
    display: flex;
    margin-top: 20px;
    li{
        width: 25%;
        text-align: left;
        img{
            max-width: 25px;
        }
    }
}
.calendarManagement{
    .card{
        overflow: visible !important;
        border: none;
        .card-header{
            text-transform: uppercase;
            font-size: 20px;
            color: $color-primary;
        }
        
    }
    .card-header:not(.collapsed){
        .icon-arrow{
            position: absolute;
            left: -5px;
        }
    }
    .card-header:not(.collapsed) {
        border: 1px solid $color8;
        border-bottom: none;
        padding-left: 40px;
        margin-left: 15px;
        margin-top: 20px;
    }
    .card-header.collapsed{
        border: none;
        position: relative;
        left: -60px;
        padding: 10px 0px;
    }
    .collapse.show{
        border: 1px solid $color8;
        border-top: none;
        padding-left: 20px;
        margin-left: 15px;
        margin-bottom: 20px;
        .card-body{
            padding-top: 0px;
        }
    }
}
.banner{
    position: relative;
    .banner-image{
        position: absolute;
        top: 0px;
        width:100%;
    }
    .banner-content-left{
        position: absolute;
        top: 50%;
        left: 10%;
        width: 40%;
        transform: translate(-0%, -50%);
        h1{
            font-size: 40px;
            font-weight: 500;
            line-height: 42px;
            color: #3384b4;
            width: 50%;
            margin: 0px;
        }
        h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            color: #3384b4;
            width: 50%;
            margin: 0px;
        }
        p{
            margin-top: 8px;
        }
    }
    .banner-content-center{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-wrap: wrap;
        h1{
            font-size: 40px;
            font-weight: 500;
            line-height: 42px;
            color: #3384b4;
            width: 50%;
            margin: 0px;
        }
        p{
            margin-top: 8px;
        }
    }
    .common-banner-content{
        h1{
            font-size: 40px;
            font-weight: 500;
            line-height: 42px;
            color: #3384b4;
            width: 50%;
            margin: 0px;
        }
        p{
            margin-top: 8px;
        }
    }
}
.heading-text{
    font-size: 40px;
    font-weight: 500;
    line-height: 42px;
    color: #3384b4;
}
.form-group.half-group {
    width: calc(50% - 5px);
    
}
.form-group.half-group.ml-5px{
    margin-left: 5px !important;
}
.form-group.half-group.mr-5px{
    margin-right: 5px !important;
}
.address{
    .icon-group{
        width: 40px;
        margin-right: 10px;
        span{
            width: 40px;
            height: 40px;
            display: inline-block;
            font-size: 20px;
            background-color: $color5;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 40px;
        }
    }
    .contact-content{
        width: calc(100% - 50px);
        p{
            font-size: 15px;
            margin-bottom: 2px;
        }
    }
}
.custom{
    &.btn-sm{
    min-width: 125px;
    text-align: center;
    }
}
.page-faqs{
    .calendarManagement {
        .card-header{
            &.collapsed {
                display: flex;
                .icon-arrow, .title{
                    display: inline-block;
                }
                .title{width: calc(100% - 60px);}
            }
        }
    }
}
.dashboard-tab1{
    .mat-tab-label{
        .mat-tab-label-content{
            flex-wrap: wrap;
        }
        .icon-group{
            justify-content: center!important;
            width: 100%;
            display: flex;
        }
    }
}
.google-chart-group{
    width: 99%;
    .google-chart{
        max-width: 100%;
        div[dir="ltr"]{
            max-width: 100%;
            svg{
                max-width: 100%;
            }
        }
    }
}
.surveyResult{
    &.ag-theme-alpine {
        .ag-row{
            height: 60px !important;
        }
    }
}
.cdk-overlay-container {
    z-index: 9999;
}
.inner-btn-none{
    button{
        display: none;
    }
    .evidences-icon-container{
        margin-bottom: 0px !important;
    }
}
label{
    &.form-control{
        &.readonly{
            text-overflow: ellipsis; word-break: break-all;height: auto;
        }
    }
}
#navbarNav{
    max-width: calc(100% - 160px);
    margin-right: 20px !important;
    .customized-scrollbar{
       max-width: 100%;
       overflow-x: scroll;
       margin-bottom: 5px !important;
    }
    .customized-scrollbar::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #d2d2d2;
    }
    
    .customized-scrollbar::-webkit-scrollbar-thumb {
        background: $color7;
    }
  }
.mat-card-image {
    min-width: calc(100% + 32px);
    
}
.w-fit-comtent{
    width: fit-content !important;
}